/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  function init_select2 () {
    $('div.mc4wp-form-fields select#MMERGE2').select2({
      minimumResultsForSearch: Infinity
    });

    $('div.mc4wp-form-fields select#interests').select2({
      placeholder: "Interests"
    });
  }


  function set_calendar_item_height () {

    if ($('.event-calendar-item').length) {
      var calendar_items = $('.event-calendar-item');

      calendar_items.each(function () {
        var $this = $(this),
          main_img = $this.find('img.image-main'),
          img_wrap = $this.find('div.img-wrap'),
          content = $this.find('div.content-wrap div.inner');

        main_img.height(parseInt(img_wrap.height()) - parseInt(content.outerHeight()));
        $this.addClass('loaded');
      });
    }

  }//end set_calendar_item_height


  function set_filter_bar_sticky () {

    //enable the sticky filter bar
    if ($('section#filter-bar-section').length) {
      var os = parseInt($('header.header').outerHeight());
      var filter = $('section#filter-bar-section');

      filter.trigger("sticky_kit:detach");
      filter.stick_in_parent({
        offset_top: os
      });
    } else {
      if ($('div.events-calendar-filter-row').length) {
        var os = parseInt($('header.header').outerHeight());
        var filter = $('div.events-calendar-filter-row');

        filter.trigger("sticky_kit:detach");
        filter.stick_in_parent({
          offset_top: os
        });
      }
    }

  }


  function init_isotope() {
    var $container = $('.isotope-wrapper');

    if ($container.length) {
      $container.isotope({itemSelector: '.isotope-item', layoutMode: 'masonry'});

      $('.event-filter-bar ul > li').on('click', function () {

        $('.event-filter-bar ul > li').removeClass('active');

        var $this = $(this);
        $this.addClass('active');

        var selector = $(this).attr('data-filter');
        $('.isotope-wrapper').isotope({filter: selector});

        $("html, body").stop().animate({
          scrollTop: (parseInt($('span#isotope-scroll-target').offset().top) - parseInt($('header.header').outerHeight()))
        }, 500, "easeInOutExpo");
      });

      set_filter_bar_sticky();

    }
  }//end init_isotope


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        init_select2();

        //Event calendar items: set the image height based on the content
        imagesLoaded('div.event-calendar-item img.image-main', function () {
          set_calendar_item_height();
        });


        //Isotope for the calendar items
        init_isotope();


        //Inactive link click action
        $('a.inactive-link').click(function(e){
          e.preventDefault();
          return false;
        });


        //Calendar item image anchor click - touch device - prevent default
        $('html.touchevents a.calendar-item-img-anchor').click(function(e){
          e.preventDefault();
          return false;
        });


        //Interests - disable search in select
        $('div.mc4wp-form-fields select#interests').on('select2:opening select2:closing', function( event ) {
            var $searchfield = $( '#'+event.target.id ).parent().find('.select2-search__field');
            $searchfield.prop('disabled', true);
        });


        //OnScroll
        var header = $('header.header');
        $(window).on('scroll', function () {
          if ($(this).scrollTop() > header.height()) {
            if (!header.hasClass('sticky')) {
              header.addClass("sticky");
              setTimeout(function () {
                set_filter_bar_sticky();
              }, 350);

            }
          } else {
            if (header.hasClass('sticky')) {
              header.removeClass("sticky");
              setTimeout(function () {
                set_filter_bar_sticky();
              }, 350);
            }
          }
        });//end on Scroll



        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('div.navbar-collapse').toggleClass('collapse');
          $('header.header').toggleClass('active');
        });



        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });


        //Page scroll
        $("a.page-scroll").bind("click", function (e) {
          var offset = $('header.header').outerHeight(),
              t = $(this);

          var elem = $(t.attr("href"));
          if (t.attr("href") === '#next-section') {

            if (t.closest('section').next().attr('id') === 'isotope-scroll-target') {
              elem = t.closest('section').next('span#isotope-scroll-target').next('section');
            } else {
              elem = t.closest('section').next('section');
            }

          }

          $("html, body").stop().animate({
            scrollTop: (parseInt(elem.offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });





        //Video popups
        $('.video').magnificPopup({
          type: 'iframe',
          preloader: true,
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function () {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            }
          },
          closeOnContentClick: true
        });//end video modal



        //Image popups
        $('.magnific-gallery').each(function () {
          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            preloader: true,
            gallery: {
              enabled: true
            },
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
              beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
              }
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
          });
        });//end image modal



        //Hero gallery buttons click:
        $('a.hero-gallery-btn').click(function(e){
          e.preventDefault();

          var id = $(this).data('gallery');

          //Trigger click on first gallery item:
          $('div.magnific-gallery.'+id+' a:first-child').trigger('click');

          return false;

        });


        //Image carousel
        $('.image-carousel').owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          navText: ['<img src="/wp-content/themes/OCISport/dist/images/icons/chevron-left.png" alt="Arrow left"/>', '<img src="/wp-content/themes/OCISport/dist/images/icons/chevron-right.png" alt="Arrow left"/>']
        });//end img carousel


        $('.partners-carousel').owlCarousel({
          items: 6,
          dots: false,
          nav: true,
          loop: true,
          autoplay: true,
          navText: ['<img src="/wp-content/themes/OCISport/dist/images/icons/chevron-left.png" alt="Arrow left"/>', '<img src="/wp-content/themes/OCISport/dist/images/icons/chevron-right.png" alt="Arrow left"/>'],
          responsiveClass:true,
          responsive:{
            0: {
              items: 2,
              nav: false,
              dots: true
            },
            768: {
              items: 3,
              nav: false,
              dots: true
            },
            1024: {
              items: 5,
              nav: true,
              dots: false
            },
            1025: {
              items: 6,
              nav: true,
              dots: false
            }
          }
        });//end img carousel



        //Events table filter clicks:
        if ($('section#events div.event-filter-bar').length) {


          var data_table = $('table#events-table').DataTable({
            paging: false,
            order: [[ 0, "asc" ]]
          });

          var filters = $('section#events div.event-filter-bar ul li');
          filters.click(function(e){

            e.stopPropagation();

            var $this = $(this);
            if ($('html').hasClass('touchevents') && $this.hasClass('has-ddm') && !$this.hasClass('ddm-open')) {
              $this.addClass('active').addClass('ddm-open');
              return;
            }


            if (!$this.hasClass('bike-filter')) {
              filters.removeClass('active').removeClass('ddm-open');
            } else {
              $('li.bike-filter').removeClass('active');
            }
            $this.addClass('active');

            var filter = $this.data('filter');
            if (filter === '*') {
              filter = '';
            } else {
              filter = filter.replace('.', '');
            }

            data_table.columns(0).search(filter).draw();

          });


        }//events filter bar


















        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
